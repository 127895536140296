export default {
  'customer.name': 'Customer management',
  'customer.customerName': 'Customer name',
  'customer.customerEmail': 'Customer email',
  'customer.customerPhone': 'Customer phone',
  'customer.customerAddress': 'Customer address',
  'customer.customerCoin': 'Customer coin',
  'customer.customerCard': 'Customer Card',
  'customer.action': 'Action',
  'customer.internalComment': 'Internal Comment',
  'customer.update': 'Update customer',
  'customer.create': 'Create customer',
  'customer.password': 'Password',
  'customer.customerCode': 'Code',
  'customer.customerBirthday': 'Date of birth',
  'customer.customerVerification': 'Confirmation status',
  'customer.isActive': 'Active status',
  'customer.statusConfirm': 'Confirmed',
  'customer.statusUnConfirm': 'Unconfimred',
  'customer.information': 'Information',
  'customer.delete': 'Delete customers',
  'customer.content.delete': 'Are you sure you want to delete this customer?',
  'customer.amountEntry': 'Checking status',
  'customer.customerAvatar': 'Avatar',
  'customer.customerCard.null': 'No card',
  'customer.customerCard.not-null': 'There are cards',
  'customer.attendeeName': 'Attendee name',
  'customer.orderNumber': 'Order number',
  'customer.qrCode.subTitle': 'Scan this code to get card information',
  'customer.ticketType': 'Ticket type',
};
